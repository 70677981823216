<template>
  <div class="service">
    <div class="condition_div">
      <el-form :inline="true" class="flex">
        <el-form-item>
          <el-button type="primary" size="small" @click="add('')">新增</el-button>
        </el-form-item>
        <div>
          <el-form-item>
            <el-input v-model="searchForm.name" placeholder="请输入类型名称" suffix-icon="el-icon-search" clearable></el-input>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" size="small" @click="getList(true)">查询</el-button>
          </el-form-item>
        </div>
      </el-form>
    </div>
    <el-table :data="tableData" style="width: 100%" class="table" :height="tableHeight">
      <el-table-column type="index" label="序号" width="60" align="center" fixed>
        <template slot-scope="scope">{{scope.$index+(searchForm.page-1) * searchForm.limit + 1}}</template>
      </el-table-column>
      <el-table-column prop="titleName" label="类型名称" min-width="120" align="center"></el-table-column>
      <el-table-column prop="titleMemo" label="备注" min-width="120" align="center"></el-table-column>
      <el-table-column prop="titleUpdatetime" label="最后操作时间" min-width="200" align="center"></el-table-column>
      <el-table-column prop="operateName" label="操作人" min-width="120" align="center"></el-table-column>
      <el-table-column prop="titleFlag" label="当前状态" min-width="100" align="center">
        <template slot-scope="scope">
          <span class="dot" :class="stateClass(scope.row.titleFlag)"></span> {{showState(scope.row.titleFlag)}}
        </template>
      </el-table-column>
      <el-table-column fixed="right" label="操作" width="130" align="center">
        <template slot-scope="scope">
          <el-button type="text" size="small" @click="changeState(scope.row)">
            {{scope.row.titleFlag==1?"禁用":"启用"}}
          </el-button> <span class="ge">|</span>
          <el-button type="text" size="small" @click="add(scope.row)"> 编辑</el-button>
        </template>
      </el-table-column>
    </el-table>
    <pagination :page="searchForm.page" :size="searchForm.limit" :total="total" @handleSizeChange="handleSizeChange"
      @handleCurrentChange="handleCurrentChange"></pagination>
    <!-- 新增\编辑 -->
    <modal :obj="{
        title: edit ? '编辑' : '新增',
        dialog: addDialog,
        dialogText: 'addDialog',
        form: addForm,
        ref: 'addForm',
      }" @submit="addSubmit">
      <el-form :model="addForm" label-width="130px" :rules="rules" ref="addForm">
        <el-form-item label="类型名称：" prop="titleName">
          <el-input v-model="addForm.titleName" placeholder="请输入标题"></el-input>
        </el-form-item>
        <el-form-item label="操作人：">
          <el-input v-model="updateman" placeholder="请输入操作人" disabled></el-input>
        </el-form-item>
        <el-form-item label="状态：" prop="titleFlag">
          <el-select v-model="addForm.titleFlag" placeholder="请选择状态" class="w_100">
            <el-option v-for="(item, index) in stateList" :key="index" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="备注：" prop="titleMemo">
          <el-input type="textarea" v-model="addForm.titleMemo" :rows="4" placeholder="请输入备注"></el-input>
        </el-form-item>
      </el-form>
    </modal>
  </div>
</template>

<script>
  import pagination from "../../../components/pagination";
  import modal from "../../../components/modal";
  import {
    consultTitleList,
    consultTitleUpdown,
    consultTitleAdd,
    consultTitleUpdate,
    consultTitleInfo,
  } from "../../../api/api";
  export default {
    components: {
      pagination,
      modal,
    },
    data() {
      return {
        searchForm: {
          name: "",
          page: 1,
          limit: 10,
        },
        total: 0,
        tableHeight: this.$util.getHeight(330),
        tableData: [],
        stateList: [{
            value: "0",
            label: "禁用",
          },
          {
            value: "1",
            label: "启用",
          },
        ],
        showBtn: false,
        addDialog: false,
        edit: "",
        addForm: {
          titleName: "",
          titleFlag: "",
          titleMemo: "",
        },
        rules: {
          titleName: [{
            required: true,
            message: "请选择类型名称"
          }],
          titleFlag: [{
            required: true,
            message: "请选择状态"
          }],
        },
        updateman: "",
      };
    },
    computed: {
      userInof() {
        return this.$store.state.userInof;
      },
      poewrMenuIdList() {
        return this.$store.state.poewrMenuIdList ? this.$store.state.poewrMenuIdList : []
      },
    },
    created() {
      // this.getList();
    },
    mounted() {
      window.addEventListener('resize', () => {
        this.tableHeight = this.$util.getHeight(330)
      })
    },
    methods: {
      //   新增\编辑-弹框
      add(val) {
        this.edit = val;
        this.addDialog = true;
        this.addForm = {
          titleName: "",
          titleFlag: "",
          titleMemo: "",
        };
        if (this.$refs.addForm) {
          this.$refs.addForm.resetFields();
        }
        if (this.edit) {
          this.getConsultTitleInfo(val);
          this.updateman = val.operateName;
        } else {
          this.updateman = this.userInof.name;
        }
      },
      // 查询单个详情
      getConsultTitleInfo(val) {
        this.$request(consultTitleInfo(val.titleId)).then((res) => {
          if (res.code == "200") {
            this.addForm = res.data;
          }
        });
      },
      //   新增编辑-上传接口
      addSubmit() {
        let url = this.edit ? consultTitleUpdate : consultTitleAdd;
        this.$refs.addForm.validate((result) => {
          if (result) {
            this.$request(url, this.addForm).then((res) => {
              if (res.code == "200") {
                this.addDialog = false;
                this.$util.message("suc", "操作成功");
                this.getList();
              }
            });
          }
        });
      },
      //启用禁用-弹窗
      changeState(val) {
        let text = val.titleFlag == 0 ? "确认启用?" : "确认禁用?";
        this.$util.isSwal(text, this.changeStateSubmit, val);
      },
      changeStateSubmit(val) {
        let state = val.titleFlag == 0 ? "1" : "0";
        this.$request(consultTitleUpdown(val.titleId, state)).then((res) => {
          if (res.code == "200") {
            this.$util.message("suc", "操作成功");
            this.getList();
          }
        });
      },
      getList(val) {
        if (val) {
          this.searchForm.page = 1;
        }
        this.$request(consultTitleList, this.searchForm).then((res) => {
          if (res.code === "200") {
            let data = res.data;
            this.tableData = data.list;
            this.total = Number(data.totalCount);
          }
        });
      },
      handleSizeChange(val) {
        this.searchForm.limit = val;
        this.getList();
      },
      handleCurrentChange(val) {
        this.searchForm.page = val;
        this.getList();
      },
      showState(val) {
        switch (val) {
          case "0":
            return "禁用";
          case "1":
            return "启用中";
        }
      },
      stateClass(val) {
        switch (val) {
          case "0":
            return "red_dot";
          case "1":
            return "green_dot";
        }
      },
    },
  };
</script>

<style lang="less" scoped>
  .service {
    .add {
      padding-top: 3px;
      margin-right: 24px;
    }
  }
</style>